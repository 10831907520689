<template>
  <div class="spnr">Login In Progress {{ dots[count] }}</div>
</template>
<script>
export default {
  name: "LoadingSpinnerLigin",
  setup() {},
  data() {
    return {
      count: 0,
      dots: [". ....", ".. ...", "... ..", ".... .", "... ..", ".. ..."],
    };
  },
  mounted() {
    this.countTimer();
  },
  methods: {
    countTimer() {
      //     console.log(this.count);
      setTimeout(() => {
        this.count += 1;
        if (this.count >= 6) {
          this.count = 0;
        }
        this.countTimer();
      }, 500);
    },
  },
};
</script>
<style scoped>
.spnr {
  padding: 5px 10px;
  font-style: italic;
  color: rgb(59, 59, 59);
}
</style>
