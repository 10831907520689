<template>
  <div class="bg-linco">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-dark bg-linco">
        <div class="container-fluid">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link class="navbar-brand nav-link active" :to="'/'"
                  ><i class="bi bi-house"></i>&nbsp;HOME</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="navbar-brand nav-link" :to="'/products'"
                  ><i class="bi bi-box-seam"></i>&nbsp;PRODUCTS</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="navbar-brand nav-link" :to="'/about'"
                  ><i class="bi bi-info-circle"></i>&nbsp;ABOUT</router-link
                >
              </li>

              <li class="nav-item">
                <router-link class="navbar-brand nav-link" :to="'/contacts'"
                  ><i class="bi bi-envelope"></i>&nbsp;Contacts</router-link
                >
              </li>
            </ul>
            <form class="d-flex" @submit.prevent="search">
              <select
                v-model="searchParam.cat"
                class="search-select search-elements"
              >
                <option selected="selected" value="null">All Categories</option>
                <option
                  v-for="(row, index) in catlist"
                  :key="index"
                  :value="row.id"
                >
                  {{ row.name }}
                </option>
              </select>
              <input
                v-model="searchParam.key"
                class="search-elements search-text"
                placeholder="Search"
                aria-label="Search"
              />
              <button class="search-button search-elements" type="submit">
                <i class="bi bi-search"></i>
              </button>
            </form>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "NavBar",
  data() {
    return {
      searchParam: {
        key: "",
        cat: null,
      },
      catlist: {},
    };
  },
  setup() {},
  mounted() {
    this.getCatMain();
  },
  methods: {
    async search() {
      this.$router.push(
        "/search?cat=" + this.searchParam.cat + "&key=" + this.searchParam.key
      );
    },
    async getCatMain() {
      try {
        const response = await axios.get("/productscatparent");
        this.catlist = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.nav-link {
  font-weight: bold;
}
.search-elements {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
}

.search-select {
  border-top-left-radius: 999em;
  border-bottom-left-radius: 999em;
  font-size: 0.8em;
  width: fit-content;
  border-top: 1px solid var(--bg-linco);
  border-bottom: 1px solid var(--bg-linco);
  border-left: 1px solid var(--bg-linco);
  border-right: 0;
}

.search-text {
  border-top: 1px solid var(--bg-linco);
  border-bottom: 1px solid var(--bg-linco);
  border-left: 0;
  border-right: 0;
  width: 250%;
}

.search-text:focus {
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.search-button {
  border-top-right-radius: 999em;
  border-bottom-right-radius: 999em;
  font-size: 0.8em;
  width: fit-content;
  border-top: 1px solid var(--bg-linco);
  border-bottom: 1px solid var(--bg-linco);
  border-right: 1px solid var(--bg-linco);
  border-left: 0;
}
</style>
