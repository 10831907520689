<template>
  <div style="background-color: #fff">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <router-link to="/">
            <img
              src="@/assets/images/logo/logo.png"
              class="img-thumbnail"
              style="border: 0"
            />
          </router-link>
        </div>
        <div class="col-lg-6">
          <div class="d-flex align-items-center float-end auth-link-container">
            <div v-if="!user" class="box w-100 text-success">
              <a
                class="auth-links btn-rndl"
                data-bs-toggle="offcanvas"
                href="#offcanvasAuth"
                role="button"
                aria-controls="offcanvasAuth"
              >
                <i class="bi bi-unlock-fill"></i> Login
              </a>
              <router-link class="auth-links btn-rndr" :to="'/register'"
                ><i class="bi bi-person-fill-add"></i> Rgister</router-link
              >
            </div>
            <div v-if="user" class="box w-100 text-success">
              <router-link class="auth-links btn-rndl" :to="'/myprofile'"
                ><i class="bi bi-person-circle"></i>
                {{ user.namefirst }}</router-link
              >
              <router-link class="auth-links btn-rndr" :to="'/logout'"
                ><i class="bi bi-lock-fill"></i> Logout</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Navbar></Navbar>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasAuth"
    ref="offcanvasAuth"
    aria-labelledby="offcanvasExampleLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        id="closebtn"
      ></button>
    </div>
    <Transition>
      <div class="offcanvas-body" v-show="viewMode == 0">
        <div>
          <div class="header">Login</div>
          <div>
            <form @submit.prevent="tryLogin">
              <div class="form-floating mb-1">
                <input
                  type="email"
                  class="form-control form-floating-input"
                  id="floatingInput"
                  required
                  placeholder="email"
                  v-model="email"
                />
                <label for="floatingInput">Email address</label>
              </div>
              <div class="form-floating mb-3">
                <input
                  type="password"
                  class="form-control form-floating-input"
                  id="floatingPassword"
                  placeholder="Password"
                  required
                  v-model="password"
                />
                <label for="floatingPassword">Password</label>
              </div>
              <div class="text-center small">
                <LoadingSpinnerLoginVue v-if="loading" />
                {{ responseMsg }}
              </div>
              <div class="text-center">
                <button
                  class="btn btn-primary btn-round-side w-100"
                  type="submit"
                >
                  <i class="bi bi-key"></i> LOGIN
                </button>
              </div>
              <div class="mt-3">
                <span class="small">Forgot Password ? </span>
                <button class="btn btn-link" @click="tryReset">
                  Reset Password
                </button>
              </div>
              <div class="mt-3">
                <span class="small">Don't Have a Account ? </span>
                <button class="btn btn-link" @click="tryRegister">
                  Register Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
import Navbar from "./NavBar.vue";
import store from "@/store";
import axios from "axios";
import LoadingSpinnerLoginVue from "@/components/ProgressBars/LoadingSpinnerLogin.vue";
export default {
  components: {
    Navbar,
    LoadingSpinnerLoginVue,
  },
  name: "HeaderView",
  setup() {},
  data() {
    return {
      viewMode: 0,
      error: {
        email: null,
        password: null,
        namefirst: null,
        namelast: null,
      },
      loading: false,
      responseMsg: null,
      email: null,
      password: null,
      register: [],
      termsOk: false,
      privacyOk: false,
    };
  },
  computed: {
    user() {
      return store.state.user;
    },
  },
  beforeMount() {
    this.getUser();
  },
  methods: {
    async logout() {
      this.responseMsg = null;
      this.$router.push("/logout");
    },
    async getUser() {
      var token = localStorage.getItem("token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        try {
          const response = await axios.post("verify");
          //if (response.data.status == true) {
          this.$store.dispatch("user", response.data);
          // }
        } catch (err) {
          if (err) {
            this.loading = false;
          }
        }
      }
    },
    async tryLogin() {
      this.responseMsg = null;
      this.loading = true;
      try {
        const response = await axios.post("/login", {
          email: this.email,
          password: this.password,
        });

        if (response.data.status == true) {
          localStorage.setItem("token", response.data.token);
          this.$store.dispatch("user", response.data.user);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.token;

          this.responseMsg = null;
          document.getElementById("closebtn").click();
        } else {
          this.responseMsg = response.data.msg;
        }
        this.loading = false;
      } catch (err) {
        if (err) {
          this.loading = false;
          this.responseMsg = err.response.data.msg;
        }
      }
    },
    async tryRegister() {
      document.getElementById("closebtn").click();
      //this.$router.push("/register");
      this.$router.push("/registersuspened");
    },
    async tryReset() {
      document.getElementById("closebtn").click();
      this.$router.push("/forgot_password");
    },
  },
};
</script>
<style>
.bg-ash {
  background-color: rgb(240, 240, 240);
}

.router-link-active,
.router-link-exact-active {
  text-decoration: none;
  color: brown;
}

.auth-link-container {
  min-height: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
}

.auth-links {
  text-decoration: none;
  padding: 0.225rem 0.75rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgb(241, 241, 241);
  background-clip: padding-box;
  border: 1px solid #e7e7e7;
  width: fit-content;
}

.btn-rndl {
  border-top-left-radius: 999em;
  border-bottom-left-radius: 999em;
}

.btn-rndr {
  border-top-right-radius: 999em;
  border-bottom-right-radius: 999em;
}

.btn-ra {
  border-top-right-radius: 999em;
  border-bottom-right-radius: 999em;
  border-top-left-radius: 999em;
  border-bottom-left-radius: 999em;
}

.btn-my-account {
  border-radius: 999em;
}

.float-group {
  border: 0;
}
</style>
