import { createStore } from "vuex";

function initialState() {
  return {
    token: null,
    user: null,
    pageTitle: "Dashboard",
    showProfileMenu: false,
    appSettings: null,
  };
}

export default createStore({
  state: initialState,
  getters: {
    user: (state) => {
      return state.user;
    },
    token: (state) => {
      return state.token;
    },
    pageTitle: (state) => {
      return state.pageTitle;
    },
    showProfileMenu: (state) => {
      return state.showProfileMenu;
    },
    appSettings: (state) => {
      return state.appSettings;
    },
  },
  actions: {
    user(context, user) {
      context.commit("user", user);
    },
    token(context, token) {
      context.commit("token", token);
    },
    pageTitle(context, pageTitle) {
      context.commit("pageTitle", pageTitle);
    },
    showProfileMenu(context, showProfileMenu) {
      context.commit("showProfileMenu", showProfileMenu);
    },
    appSettings(context, appSettings) {
      context.commit("appSettings", appSettings);
    },
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    token(state, token) {
      state.token = token;
    },
    pageTitle(state, pageTitle) {
      state.pageTitle = pageTitle;
    },
    showProfileMenu(state, showProfileMenu) {
      state.showProfileMenu = showProfileMenu;
    },
    appSettings(state, appSettings) {
      state.appSettings = appSettings;
    },
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
});
