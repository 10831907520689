import { createRouter, createWebHistory } from "vue-router";

import store from "../store";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/FrondEnd/HomeView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../views/FrondEnd/PageNotFound.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/FrondEnd/MyAccount/LogoutView.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/FrondEnd/Auth/RegisterSuspended"),
  },
  {
    path: "/registersuspened",
    name: "Register Suspened",
    component: () => import("../views/FrondEnd/Auth/RegisterSuspended"),
  },
  {
    path: "/forgot_password",
    name: "Forgot Pasword",
    component: () => import("../views/FrondEnd/Auth/ForgotPassword"),
  },
  {
    path: "/reset_password",
    name: "New Pasword",
    component: () => import("../views/FrondEnd/Auth/NewPassword"),
  },
  {
    path: "/privacy_policy",
    name: "Privacy Policy",
    component: () => import("../views/FrondEnd/PrivacyAndTerms/PrivacyPolicy"),
  },
  {
    path: "/terms_and_conditions",
    name: "Terms And Conditions",
    component: () =>
      import("../views/FrondEnd/PrivacyAndTerms/TermsAndConditions"),
  },
    {
    path: "/about",
    name: "About",
    component: () =>
      import("../views/FrondEnd/AboutView"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () =>
      import("../views/FrondEnd/ContactView"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/FrondEnd/SearchView.vue"),
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/FrondEnd/SearchView.vue"),
  },
  {
    path: "/product/:slug",
    name: "Product",
    component: () => import("../views/FrondEnd/ProductView.vue"),
  },

  {
    path: "/productnotfound",
    name: "Product Not Found",
    component: () => import("../views/FrondEnd/ProductNotFound.vue"),
  },
  {
    path: "/seller/:slug",
    name: "Seller",
    component: () => import("../views/FrondEnd/SellersHome.vue"),
  },
  {
    path: "/myprofile",
    name: "My Profile",
    component: () => import("../views/FrondEnd/MyAccount/MyProfile"),
  },
  {
    path: "/myproducts",
    name: "My Products",
    component: () => import("../views/FrondEnd/MyAccount/MyProducts"),
  },
  {
    path: "/mystore",
    name: "My Store",
    component: () => import("../views/FrondEnd/MyAccount/MyStore"),
  },
  {
    path: "/myinvoices",
    name: "My Invoices",
    component: () => import("../views/FrondEnd/MyAccount/MyInvoices"),
  },
  {
    path: "/payment",
    name: "Payment",
    component: () => import("../views/FrondEnd/PaymentView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const protectedRoutes = [
  "My Profile",
  "My Products",
  "My Store",
  "My Invoices",
];

router.beforeEach((to, from, next) => {
  const isProtected = protectedRoutes.includes(to.name);
  if (isProtected && store.state.user) {
    store.commit("showProfileMenu", true);
  } else {
    store.commit("showProfileMenu", false);
  }

  if (isProtected && !store.state.user) {
    next({
      path: "/",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
