<template>
  <div class="sidenav" v-if="this.$store.state.user">
    <div class="st bg-success">
      <i class="bi bi-person-circle"></i>
      {{ this.$store.state.user.namefirst }}&nbsp;{{
        this.$store.state.user.namelast
      }}
    </div>
    <ul>
      <li>
        <router-link :to="'/myprofile'"
          ><i class="bi bi-person-vcard"></i>&nbsp;Profile</router-link
        >
      </li>
      <li>
        <router-link :to="'/mystore'"
          ><i class="bi bi-shop"></i>&nbsp;My Store</router-link
        >
      </li>
      <li>
        <router-link :to="'/myproducts'"
          ><i class="bi bi-list-stars"></i>&nbsp;My Products</router-link
        >
      </li>
      <li>
        <router-link :to="'/myinvoices'"
          ><i class="bi bi-currency-dollar"></i>&nbsp;My Invoices</router-link
        >
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>
<style>
.sidenav {
  float: left;
  background-color: rgb(255, 255, 255);
  width: 250px;
  margin-right: 10px;
}
.sidenav ul,
.sidenav li,
.sidenav a {
  color: rgb(71, 71, 71);
  text-decoration: none;
  list-style: none;
}
.sidenav a {
  width: 100%;
  display: block;
}
.sidenav ul {
  padding: 0;
}
.sidenav li {
  padding: 5px 20px;
  font-weight: bold;
  border-bottom: solid thin #dad0d0;
  border-right: solid thin #dad0d0;
  border-left: solid thin #dad0d0;
}
.sidenav li:hover {
  background-color: rgb(231, 231, 231);
}

.st {
  padding: 10px 20px;
  color: aliceblue;
  font-weight: bold;
}
</style>
