<template>
  <HeaderView></HeaderView>
  <div>
    <SideNavVue v-show="this.$store.state.showProfileMenu"></SideNavVue>
    <router-view :key="$route.fullPath"></router-view>
  </div>

  <FooterView></FooterView>
  <notifications position="top right" />
</template>
<script>
import HeaderView from "./views/FrondEnd/Layout/HeaderView.vue";
import FooterView from "./views/FrondEnd/Layout/FooterView.vue";
import SideNavVue from "./views/FrondEnd/Layout/SideNav.vue";

export default {
  components: {
    HeaderView,
    FooterView,
    SideNavVue,
  },
  setup() {},
  beforeMount() {
    this.$store.dispatch("showProfileMenu", false);
  },
  methods: {},
};
</script>
<style>
#app {
  font-family: "Noto Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f0f0f0;
  scrollbar-width: thin;          /* "auto" or "thin" */
}
</style>
