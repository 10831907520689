<template>
  <div class="fmenu">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <h5>Privacy & Terms</h5>
          <ul>
            <li>
              <router-link :to="'/privacy_policy'">Privacy Policy</router-link>
            </li>
            <li>
              <router-link :to="'/terms_and_conditions'"
                >Terms & Conditions</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <h5>Quick Links</h5>
          <ul>
            <li>
              <router-link :to="'/about'">About Us</router-link>
            </li>
            <li>
              <router-link :to="'/contacts'">Contact Us</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 text-center f">
        LANKA INDUSTRIAL COMPONENTS © 2018 - {{ new Date().getFullYear() }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {},
  data() {
    return {
      dt: new Date().getFullYear.toString,
    };
  },
};
</script>
<style>
.f {
  font-size: small;
  padding: 10px;
  background-color: rgb(224, 224, 224);
  color: rgb(66, 66, 66);
}
.fmenu {
  padding-top: 20px;
  background-color: rgb(54, 54, 54);
  color: rgb(219, 219, 219);
}
.fmenu ul,
.fmenu li,
.fmenu a {
  color: rgb(194, 194, 194);
  text-decoration: none;
  list-style: none;
  padding-left: px;
}
.fmenu a:hover {
  color: rgb(92, 92, 92);
}
</style>
