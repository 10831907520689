import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import { createHead } from "@vueuse/head";
const head = createHead();

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/fonts/noto-sans/stylesheet.css";
import "./assets/fonts/kode-mono/stylesheet.css";

import Notifications from "@kyvg/vue3-notification";

import "./assets/styles/css.css";
import "./assets/styles/products.css";
import "./assets/styles/transition.css";

import "bootstrap-5-vertical-tabs/dist/b5vtabs.min.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

axios.defaults.baseURL = "https://api.linco.lk/api/";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

const app = createApp(App);
app.config.globalProperties.$ContentUrl = "https://content.linco.lk/";

import vue3GoogleLogin from "vue3-google-login";
app.use(vue3GoogleLogin, {
  clientId:
    "365798210701-toro795kru0bprmou130qi4l392egvd5.apps.googleusercontent.com",
});

import VueGtag from "vue-gtag";
app.use(VueGtag, {
  config: {
    id: "G-M2EWRH39Y9",
  },
});

//import Ads from "vue-google-adsense";
//app.use(Ads.Adsense);
//app.use(Ads.InArticleAdsense);
//app.use(Ads.InFeedAdsense);

import Adsense from "vue-google-adsense/dist/Adsense.min.js";

import ScriptX from "vue-scriptx";
app.use(ScriptX);
app.use(Adsense);

app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(Notifications);
app.use(VueSweetalert2);
app.use(head);
app.mount("#app");
